import { string, object, number, lazy, array, mixed } from 'yup'

 const getSpecificationErrorMessage = ({ value: { id } }, categorySpecifications) => {
	const findCategorySpecification = Array.isArray(categorySpecifications)
		? categorySpecifications.find(catSpec => catSpec.specification.id === id)
		: null

	return findCategorySpecification ? `${findCategorySpecification.specification.title} je obavezno polje.` : ''
}

export const getValidationSchema = ({ categorySpecifications = [] }) => {
	const validation = {}
	validation.specifications = lazy(() => {
		return array().of(object().test('requiredSpec', options => getSpecificationErrorMessage(options, categorySpecifications), formSpec => {
			let isRequired = true

			if (formSpec.isHidden) {
				isRequired = false
			} else {
				const findCategorySpecification = Array.isArray(categorySpecifications)
					? categorySpecifications.find(catSpec => catSpec.specification.id === formSpec.id)
					: null
	
				if (findCategorySpecification) {
					isRequired = findCategorySpecification.required
				} else {
					isRequired = true
				}
			}

			const isValid = !isRequired || (typeof formSpec.value !== 'undefined' && Number(formSpec.value) !== -1)
			return isValid
		}))
	})

	const schema = object().shape(validation)

	return schema
}
