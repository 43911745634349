import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import withTranslation from 'next-translate/withTranslation'
import Router from 'next/router'
import StpdBannerComponent from 'stpd-ad-component'
import { Page, PageHeader, AddPageContent, Page404 } from '../src/ui'
import { CommonForm } from '../src/modules/common/components/FormPage/Form'
import { fetchFormFields } from '../src/modules/common/actions'
import { getFormFields } from '../src/modules/common/selectors'
import initialState from '../src/modules/redux/initialState'
import { deepCopy } from '../src/utils'
import config from '../src/config/config.json'

const DesktopBanner = () => {
  return (
    <div style={{ width: 630, height: 250 }}>
      <StpdBannerComponent
        refreshOnUrlChange={true}
        adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.vehicleRegistration.desktop.adUnit}`}
        size={config.banners.vehicleRegistration.desktop.sizes}
        divId={config.banners.vehicleRegistration.desktop.slotId}
      />
    </div>
  )
}

const MobileTopBanner = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const isTablet = windowWidth > 727

  let adUnitPath = `/${config.banners.dfpNetworkId}/${config.banners.vehicleRegistration.pwaMobileTop.adUnit}`
  if (isTablet) adUnitPath = `/${config.banners.dfpNetworkId}/${config.banners.vehicleRegistration.pwaMobileTopTablet.adUnit}`

  let size = config.banners.vehicleRegistration.pwaMobileTop.sizes
  if (isTablet) size = config.banners.vehicleRegistration.pwaMobileTopTablet.sizes

  let slotId = config.banners.vehicleRegistration.pwaMobileTop.slotId
  if (isTablet) slotId = config.banners.vehicleRegistration.pwaMobileTopTablet.slotId

  return (
    <div style={{ width: !isTablet ? 320 : 728, margin: 'auto' }}>
      <StpdBannerComponent
        refreshOnUrlChange={true}
        adUnitPath={adUnitPath}
        size={size}
        divId={slotId}
      />
    </div>
  )
}

const MobileBottomBanner = () => {
  return (
    <div style={{ width: 320, margin: 'auto' }}>
      <StpdBannerComponent
        refreshOnUrlChange={true}
        adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.vehicleRegistration.pwaMobile.adUnit}`}
        size={config.banners.vehicleRegistration.pwaMobile.sizes}
        divId={config.banners.vehicleRegistration.pwaMobile.slotId}
      />
    </div>
  )
}

class FormRequestPage extends React.Component {
  static async getInitialProps({ store, req, res, query }) {
    let path = req ? req.url : query ? query.url : null
    let component, category, hrefUrl, asUrl, urlQuery, phones
    let isPageExists = false

    if (path) path = path.replace(/\/me|\/ru|\/en|\/ua\/de\/al\/tr/, '')

    if (path) {
      const formPage = config.pages.formRequest[path] ? config.pages.formRequest[path] : null

      if (formPage) {
        component = formPage.component
        hrefUrl = formPage.hrefUrl
        asUrl = formPage.asUrl
        urlQuery = formPage.urlQuery
        phones = formPage.phones === 'default' ? config.pages.formRequest.phones : formPage.phones

        const categorySeo = formPage.categorySeo
        if (categorySeo) {
          const isAuthorized = store.getState().user.auth.isAuthorized
          if (categorySeo === 'korisnicka-podrska' && !isAuthorized) {
            if (res) {
              res.redirect('/profil')
            }

            Router.replace('/profile', '/profil')
          }

          console.log('categorySeo', categorySeo)

          await store.dispatch(fetchFormFields(categorySeo))

          const fields = getFormFields(store.getState())

          category = fields ? fields.category : null
          if (category && category.id) {
            isPageExists = true
          }
        }
      }
    }

    return {
      title: category && category.title
        ? `${config.pages.formRequest[path].title} - ${category.title} | ${config.siteName}`
        : isPageExists
          ? `${config.pages.formRequest[path].title} | ${config.siteName}`
          : config.pages.notFoundTitle,
      category,
      path,
      component,
      phones,
      hrefUrl,
      asUrl,
      urlQuery,
      isPageExists,
      isMobileDevice: store.getState().common.isMobileDevice,
      state: FormRequestPage.getPageState(store, isPageExists)
    }
  }

  static getPageState(store, isPageExists = false) {
    const state = deepCopy(initialState)
    if (store) {
      const currentState = store.getState()

      state.user.auth.isAuthorized = currentState.user.auth.isAuthorized
      state.user.profile.error = currentState.user.profile.error
      state.user.profile.info = currentState.user.profile.info
      state.user.profile.favouriteAds = currentState.user.profile.favouriteAds
      state.user.profile.savedFilters = currentState.user.profile.savedFilters
      state.user.profile.favouriteProfiles = currentState.user.profile.favouriteProfiles
      state.user.profile.priceTypes = currentState.user.profile.priceTypes

      state.common.transitions = currentState.common.transitions
      state.common.router = currentState.common.router
      state.common.is404 = !isPageExists
      state.common.isMobileDevice = currentState.common.isMobileDevice
      state.common.userTypes = currentState.common.userTypes
      state.common.form = currentState.common.form
      state.common.pageMetaTags = currentState.common.pageMetaTags
      state.common.pageMetaTagsSingle = currentState.common.pageMetaTagsSingle

      state.categories.elements = currentState.categories.elements
      state.categories.specifications = currentState.categories.specifications
      state.categories.currentSubcategory = currentState.categories.currentSubcategory

      state.groups.elements = currentState.groups.elements

      state.ads.detail.reportTypes = currentState.ads.detail.reportTypes
      state.ads.detail.registrationFormulas = currentState.ads.detail.registrationFormulas
    }
    return state
  }

  renderBanner() {
    if (!config.banners.vehicleRegistration) return null

    if (this.props.isMobileDevice) {
      return (
        <div style={{ textAlign: 'center' }}>
          <MobileBottomBanner />
        </div>
      )
    }

    return <DesktopBanner />
  }

  renderMobileBannerTop = () => {
    return this.props.isMobileDevice ? (
      <div style={{ textAlign: 'center' }}>
        <MobileTopBanner />
      </div>
    ) : null
  }

  render() {
    const { isPageExists, category, path, phones, hrefUrl, asUrl, urlQuery, isForApp, isMobileDevice, i18n: { t } } = this.props

    console.log('path', path)
    return isPageExists
      ? <Page>
        <PageHeader isForApp={isForApp}>{t(`common:${config.pages.formRequest[path].title}`)}</PageHeader>
        {path.includes('registracija-vozila') ? this.renderMobileBannerTop() : null}
        <AddPageContent
          isForApp={isForApp}
          descriptionClass={category && category.seo ? 'heading-lead' : ''}
          title={category ? category.title : t(`common:${config.pages.formRequest[path].title}`)}
          description={config.pages.formRequest[path].description ? t(`common:${config.pages.formRequest[path].description}`) : null}
        >
          <CommonForm
            contactPhones={phones}
            hrefUrl={hrefUrl} asUrl={asUrl}
            urlQuery={urlQuery}
            needToVerifySms
            isForApp={isForApp}
          />

          {path.includes('registracija-vozila') ? this.renderBanner() : null}
        </AddPageContent>
      </Page>
      : <Page404 />
  }
}

FormRequestPage.propTypes = {
  category: PropTypes.string,
  path: PropTypes.string.isRequired,
  phones: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    isRequired: PropTypes.bool
  })),
  isPageExists: PropTypes.bool.isRequired,
  hrefUrl: PropTypes.string,
  asUrl: PropTypes.string,
  urlQuery: PropTypes.object,
  isForApp: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired
}

FormRequestPage.defaultProps = {
  isPageExists: false
}

export default withTranslation(FormRequestPage)
