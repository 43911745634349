import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import withTranslation from 'next-translate/withTranslation'
import { connect } from 'react-redux'
import { RegistrationApi } from 'SRC/modules/users/Registration/api'
import { getDial } from 'SRC/modules/users/Registration/selectors'
import { getSmsCodeVerified, getSmsWasSent } from '../../../selectors'
import { setSmsCodeVerified, setSmsWasSent, setFormError } from '../../../actions'
import { CommonApi } from '../../../api'
import { connect as connectFormik } from 'formik'

class SmsVerification extends React.Component {
  constructor (props) {
    super(props)

    this.secondsCounter = 120
    this.verificationCodeLength = 6

    this.state = {
      secondsRemaining: this.secondsCounter,
      verificationCode: '',
      wasResend: false,
      confirmButtonDisabled: false
    }
  }

  componentWillUnmount () {
    if (this.smsVerificationInterval) {
      clearInterval(this.smsVerificationInterval)
    }
  }

  decreaseSecondsCounter = async () => {
    await this.setState(prevState => ({
      secondsRemaining: prevState.secondsRemaining - 1
    }))
  }

  createTimer = async () => {
    await this.setState({ secondsRemaining: this.secondsCounter })

    this.smsVerificationInterval = setInterval(() => {
      this.decreaseSecondsCounter()
      if (this.state.secondsRemaining <= 0) {
        clearInterval(this.smsVerificationInterval)
      }
    }, 1000)
  }

  sendSms = async () => {
    const { formik, dial } = this.props

    console.log('formik', formik.values)
    const phone = formik.values && formik.values.phone ? formik.values.phone : null
    if (phone) {
      const api = new RegistrationApi()
      await api.sendSmsCode(`${dial}${phone.replace(/ /g, '')}`.replace('+', ''))
      this.props.setSmsWasSent(true)
      this.createTimer()
    }
  }

  verifySmsCode = async () => {
    const { formik, dial, needToSubmitForm, i18n: { t } } = this.props

    this.setState({ confirmButtonDisabled: true })
    const phone = formik.values && formik.values.phone ? formik.values.phone : null
    if (phone) {
      const api = new CommonApi()
      const result = await api.checkSmsCode(`${dial}${phone.replace(/ /g, '')}`, this.state.verificationCode)
      if (result && result.status !== -1) {
        this.props.setSmsCodeVerified(true)
        // submit
        if (!needToSubmitForm) {
          formik.setFieldValue('smsCode', this.state.verificationCode)
        }

        if (formik.submitForm && needToSubmitForm) formik.submitForm()
      } else {
        this.props.setSmsCodeVerified(false)
        // this.props.setFormError('Verifikacioni kod koji ste unijeli je netačan. Pokušajte ponovo.')
        this.props.setFormError(t('common:verifySmsCodeWrong'))
      }

      this.setState({ confirmButtonDisabled: false })
    }
  }

  setVerificationCode = e => {
    this.setState({ verificationCode: e.target.value })
  }

	getCountdownText = () => {
		const { secondsRemaining } = this.state

		let text = ''

		const minutes = Math.floor(secondsRemaining / 60)

		let seconds = '0'

		if (secondsRemaining % 60 !== 0) {
			seconds = `${secondsRemaining % 60}`
		}

		text = `0${minutes}:${seconds.length === 1 ? `0${seconds}` : `${seconds}`}`

		return text
	}

  getHelpComponent = () => {
    const { i18n: { t } } = this.props
    const { wasResend, secondsRemaining } = this.state

		if (secondsRemaining > 0) {
			return (
				<span className='ico-info-popup ico sms-code__left-column'>
					<span className='sms-code__timer'>
						{t('common:Ponovo pošalji SMS za')} {this.getCountdownText()}
					</span>
				</span>
			)
		}

		if (wasResend) {
			return (
				<span className='ico-info-popup ico sms-code__left-column'>
					<span className='sms-code__fallback-text'>{t('common:addAdSmsCodeFallback')}</span>
				</span>
			)
		}

		return (
			<span
        className='ico-info-popup ico sms-code__left-column'
        onClick={e => {
          e && e.preventDefault()
          this.sendSms()
          this.setState({ wasResend: true })
        }}
      >
				<span className='sms-code__resend-button'>{t('common:POŠALJI PONOVO')}</span>
			</span>
		)
  }

  renderVerificationCodeInput = () => {
    const { i18n: { t } } = this.props
    const { verificationCode, confirmButtonDisabled } = this.state

    return (
      <div className='sms-potvrda'>
        <p className='potvrda-msg'>
          {t('common:addAdPotvrdaSms')}
        </p>
        <div className='sms-code__form'>
          <input type='text' onChange={this.setVerificationCode} className='unesi-kod-input' />

          <button
            disabled={verificationCode.length !== this.verificationCodeLength || confirmButtonDisabled}
            className='potvrdi-btn'
            onClick={this.verifySmsCode}
          >
            {t('common:Potvrdi')}
          </button>
        </div>
       
        <div className='sms-code__help-block'>
          {this.getHelpComponent()}
          <span className='sms-code__support-text'>{t('common:addAdSmsCodeSupportText')}</span>
        </div>
      </div>
    )
  }

  render () {
    const { smsCodeWasSent, smsCodeVerified, formik, setFormError, i18n: { t } } = this.props

    return !smsCodeVerified
      ? (
        <React.Fragment>
          {!smsCodeWasSent ? (
            <button className='btn-oglas-submit ads-add__submit'
              onClick={async e => {
                if (!formik.isValid) {
                  return setFormError(t('common:addAdRequiredFieldError'))
                }

                e.preventDefault()
                this.sendSms()
              }}
              disabled={!formik.dirty}
            >
              {t('common:Pošalji SMS kod')}
            </button>
          ) : this.renderVerificationCodeInput()}
        </React.Fragment>
      ) : null
  }
}

const mapStateToProps = state => {
  return {
    smsCodeWasSent: getSmsWasSent(state),
    smsCodeVerified: getSmsCodeVerified(state),
    dial: getDial(state)
  }
}

SmsVerification.propTypes = {
  smsCodeWasSent: PropTypes.bool.isRequired,
  smsCodeVerified: PropTypes.bool.isRequired,
  formik: PropTypes.object.isRequired,
  dial: PropTypes.number.isRequired,
  needToSubmitForm: PropTypes.bool,
  setSmsWasSent: PropTypes.func.isRequired,
  setSmsCodeVerified: PropTypes.func.isRequired,
  setFormError: PropTypes.func.isRequired
}

SmsVerification.defaultProps = {
  needToSubmitForm: true
}

const enhance = compose(
  connectFormik,
  connect(mapStateToProps, { setSmsCodeVerified, setSmsWasSent, setFormError }),
  withTranslation
)

export default enhance(SmsVerification)
