import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Router from 'next/router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import parsePhoneNumber from 'libphonenumber-js'
import withTranslation from 'next-translate/withTranslation'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'
import { FormikRequired } from 'SRC/core/validators'
import { Notification } from 'react-notification'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { setNotifications } from 'SRC/modules/users/Profile/actions'
import { getDial } from 'SRC/modules/users/Registration/selectors'
import { notificationTypes } from 'SRC/modules/users/Profile/constants'
import { getInitialSpecificationValues } from 'SRC/modules/ads/shared/functions'
import { SmsVerification } from '../SmsVerification'
import { Preloader } from 'SRC/ui/Preloader'
import { SpecificationsNew, Header } from 'SRC/modules/ads/shared/components/FormElements'
import { PhoneInput } from 'SRC/ui/FormElementsNew'
import { getCurrentSubCategory, getSpecifications } from 'SRC/modules/categories/selectors'
import { getUserInfo } from 'SRC/modules/users/Profile/selectors'
import config from 'SRC/config/config.json'
import {
  getFormResult,
  getFormLoading,
  getFormError,
  getSmsCodeVerified,
  getSmsWasSent
} from '../../../selectors'
import { getValidationSchema } from '../functions'
import { CommonApi } from '../../../api'
import { submitForm, setFormError } from '../../../actions'

class CommonForm extends React.Component {
  constructor (props) {
    super(props)

    this.cookies = new CookiesHelper()

    this.state = {
      isSubmitting: false
    }
  }

  onSubmit = async (values, actions) => {
    this.setState({ isSubmitting: true })
    this.hideNotification()

    const preparedFields = this.prepareFields(values)
    console.log('pf', preparedFields)
    await this.props.submitForm(preparedFields, 'add')
    this.handleResult()
  }

  prepareFields = values => {
    const { category, contactPhones, dial } = this.props
    const formData = new FormData()

    const token = this.cookies.get('token')
    const userId = this.cookies.get('userId')
    if (token && userId) {
      formData.append('token', token)
      formData.append('userId', userId)
    }

    if (category) formData.append('form', category.id)

    if (contactPhones && contactPhones.length) {
      for (const phone of contactPhones) {
        if (values[phone.name]) {
          formData.append(phone.name, `${dial}${values[phone.name].replace(/ /g, '')}`)
        }
      }
    }

    if (values.brand) formData.append('brand', values.brand)
    if (values.product) formData.append('product', values.product)

    if (values.specifications) {
      const filledSpec = Array.isArray(values.specifications) && values.specifications.filter(item => item.value)
      if (filledSpec.length) {
        formData.append('specifications', JSON.stringify(filledSpec))
      }
    }

    if (formData.entries) {
      for (const entry of formData.entries()) {
        console.log(entry)
      }
    }
    return formData
  }

  handleResult = async () => {
    const { formResult, hrefUrl, asUrl, query } = this.props
    console.log('result', formResult)
    if (
      formResult &&
      formResult.status &&
      formResult.status === CommonApi.responseTypes.OK
    ) {
      const notifications = [
        {
          type: notificationTypes.DISCOUNT_CARD_ACTIVATED,
          info: null
        }
      ]

      await this.props.setNotifications(notifications)

      const href = { pathname: hrefUrl }
      if (query) href.query = query
      Router.push(href, {pathname: asUrl}).then(() => window.scrollTo(0, 0))
    } else {
      if (formResult.error) {
        this.props.setFormError(formResult.error)
      } else {
        this.props.setFormError(config.adForm.addAdForm.errors.DEFAULT_SERVER_ERROR)
      }

      this.setState({ isSubmitting: false })
    }
  }

  renderContact = () => {
    const { needToVerifySms, isAuthorized, dial, isForApp } = this.props

    return (
      <React.Fragment>
        {this.renderPhones()}
        <SmsVerification key='sms-verification' />
      </React.Fragment>
    )
  }

  renderPhones = () => {
    const { contactPhones, dial, isForApp, i18n: { t } } = this.props

    if (contactPhones && contactPhones.length) {
      return [<Header key='header' title={t('common:Kontakt podaci')} />,
        contactPhones.map((phone, index) => {
          const props = {
            name: phone.name,
            id: phone.name,
            label: t(`common:${phone.label}`),
            component: PhoneInput,
            dial,
            isFullWidth: isForApp
          }

          if (phone.isRequired) {
            props.isRequired = true
            props.validate = FormikRequired
          } else {
            props.isRequired = false
          }

          if (phone.isDisabled) {
            props.isDisabled = true
          }

          return (
            <React.Fragment key={`phone-${index}`}>
              <Field {...props} />
            </React.Fragment>
          )
        })]
    }
    return null
  }

  hideNotification = () => {
    this.props.setFormError('')
  }

  render () {
    const {
      initialValues,
      smsCodeWasSent,
      needToVerifySms,
      isAuthorized,
      smsCodeVerified,
      specGroups,
      isForApp,
      specifications,
      i18n: { t }
    } = this.props

    return <Formik
      onSubmit={this.onSubmit}
      initialValues={initialValues}
      validationSchema={getValidationSchema({
        categorySpecifications: specifications
      })}
      validateOnBlur={false}
    >
      {formikProps => {
        return (
          <Form className='dodavanje-oglasa-form-podaci'>
            <div className='ads-add__post-specifications'>
              <SpecificationsNew />
            </div>
    
            {this.renderContact()}

            {needToVerifySms && smsCodeWasSent && smsCodeVerified
              ? (
                <button
                  disabled={this.props.formLoading || this.state.isSubmitting || !formikProps.isValid}
                  key='submit'
                  className='btn-oglas-submit ads-add__submit'
                  type='submit'
                >
                  {t('common:Pošalji zahtjev')}
                </button>
              ) : null
            }
    
            {this.props.formLoading ? <Preloader /> : null}
    
            <Notification
              isActive={Boolean(this.props.formError)}
              message={this.props.formError || ``}
              action={'Sakrij'}
              onClick={this.hideNotification}
            />
          </Form>
        )
      }}
    </Formik>
  }
}

const mapStateToProps = (state, ownProps) => {
  const category = getCurrentSubCategory(state)
  const specifications = getSpecifications(state)

  const user = getUserInfo(state)
  const userPhones = Array.isArray(user?.phones) && user.phones.length ? user.phones : []

  let dial = getDial(state)

  const initialValues = {}

  if (category) {
    if (category.requiredAdOptions === 'BrandOnly') {
      initialValues.brand = null
    }
    if (category.requiredAdOptions === 'BrandModel') {
      initialValues.brand = null
      initialValues.product = null
    }
  }

  const contactPhones = ownProps.contactPhones
  if (contactPhones && contactPhones.length) {
    for (const phone of contactPhones) {
      if (phone.name === 'phone' && Array.isArray(userPhones) && userPhones.length) {
        const phoneNumber = userPhones.length ? userPhones[0].phone : null

        const parsedPhoneNumber = parsePhoneNumber(`+${phoneNumber.replace('+', '')}`)
        if (parsedPhoneNumber && parsedPhoneNumber.countryCallingCode) dial = parsedPhoneNumber.countryCallingCode

        initialValues[phone.name] = phoneNumber.replace(dial, '')
      } else {
        initialValues[phone.name] = null
      }
    }
  }

  initialValues.specifications = getInitialSpecificationValues({
    categorySpecifications: specifications
  })

  let specGroups = []

  if (category) {
    switch (category.seo) {
      case 'popust-kartica-forma':
        specGroups = [
          {title: 'Podaci o korisniku', positions: [0, 1, 2, 3]},
          {title: 'Podaci o vozilu', positions: [4, 5, 6, 7, 8, 9, 10, 11, 12]}
          // {title: 'Dodatna pitanja', positions: [13, 14]}
        ]
        break
      case 'zahtjev-za-djelove':
        specGroups = [
          {title: 'Podaci o korisniku', positions: [0, 1, 2]},
          {title: 'Podaci o vozilu', positions: [3, 4, 5, 6, 7, 8]}
        ]
        break
      default:
        specGroups = []
        break
    }
  }

  return {
    category,
    specifications,
    initialValues,
    formError: getFormError(state),
    formLoading: getFormLoading(state),
    formResult: getFormResult(state),
    isAuthorized: getIsAuthorized(state),
    smsCodeWasSent: getSmsWasSent(state),
    smsCodeVerified: getSmsCodeVerified(state),
    specGroups,
    dial
  }
}

CommonForm.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    requiredAdOptions: PropTypes.oneOf(['BrandModel', 'BrandOnly', 'Services', null]),
    seo: PropTypes.string
  }).isRequired,
  initialValues: PropTypes.shape({
    brand: PropTypes.number,
    product: PropTypes.number,
    specifications: PropTypes.arrayOf({
      id: PropTypes.number,
      value: PropTypes.string
    })
  }),
  specifications: PropTypes.array.isRequired,
  dial: PropTypes.number.isRequired,
  contactPhones: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    isRequired: PropTypes.bool
  })),
  hrefUrl: PropTypes.string,
  asUrl: PropTypes.string,
  query: PropTypes.object,
  formResult: PropTypes.shape(PropTypes.any).isRequired,
  formLoading: PropTypes.bool.isRequired,
  needToVerifySms: PropTypes.bool,
  smsCodeWasSent: PropTypes.bool.isRequired,
  smsCodeVerified: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isForApp: PropTypes.bool.isRequired,
  formError: PropTypes.string.isRequired,
  specGroups: PropTypes.array,
  submitForm: PropTypes.func.isRequired,
  setFormError: PropTypes.func.isRequired,
  setNotifications: PropTypes.func.isRequired
}

const enhance = compose(
  connect(mapStateToProps, { submitForm, setFormError, setNotifications }),
  withTranslation
)

export default enhance(CommonForm)
